<template>
    <div class="pa-4">
        <!-- Popups -->
        <div>
            <v-row justify="center">
                <v-dialog v-model="auth_error_enable" persistent max-width="290">
                    <v-card>
                        <v-card-title style="word-break: normal">
                            {{$t("Warning_Message.Unauthorized")}}
                        </v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text @click="closeDialogUnauthorized">
                                {{$t("Warning_Message.Ok")}}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>

        <div>
            <v-row justify="center">
                <v-dialog v-model="error_enable" persistent max-width="290">
                    <v-card>
                        <v-card-title style="word-break: normal">
                            {{$t("Warning_Message.Something_Went_Wrong")}}
                        </v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="green darken-1" text @click="closeDialogError">
                                {{$t("Warning_Message.Ok")}}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>

        <div>
            <v-row justify="center">
                <v-dialog v-model="ageLimitModal" persistent max-width="290">
                    <v-card>
                        <v-card-title style="word-break: normal">
                        {{$t("Warning_Message.Invalid_Account_YouShould_Be_Above18To_Have_An_Account")}}
                        </v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" depressed @click="closeAgeLimitModal">
                                {{$t("Warning_Message.Ok")}}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>

        <div>
            <v-row justify="center">
                <v-dialog v-model="editProfileSuccessModal" max-width="290">
                    <v-card>
                        <v-card-title style="word-break:normal; margin-top:20px;">
                            {{$t("Warning_Message.Profile_Updated_Successfully")}}
                        </v-card-title>
                        <v-card-text></v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn align="center" color="primary" depressed @click="closeEditProfileSuccessModal">
                                {{$t("Warning_Message.Ok")}}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- Popups -->

        <!-- Name -->
        <div>
            <p class="heading" align="left">{{ $t("Customer.EditProfile.Name") }}</p>
            <v-text-field v-model="editName" class="rounded-lg" style="width:100%; border-color: #E0E0E0;" solo flat outlined
                :placeholder = "translation_enter_name"
                :rules = "$store.state.locale == 'en' ? nameRules : nameRulesMM"
                background-color="#F4F6FA"
            >
            </v-text-field>
        </div>
        
        <!-- Phone Number -->
        <div>
            <p class="heading" align="left">{{ $t("Customer.EditProfile.Mobile_Number") }}</p>
            <v-text-field v-model="editMobileNumber" class="rounded-lg disabledClass" disabled solo flat outlined dark
                style="width: 100%; border-color: #E0E0E0;"
                background-color="rgb(172 180 194)"
            >
            </v-text-field>
        </div>

        <!-- NRC -->
        <div>
            <p class="heading" align="left">{{ $t("Customer.EditProfile.NRC") }}</p>
            <v-text-field v-model="editNRC" class="rounded-lg" style="width: 100%; border-color: #E0E0E0;" solo flat outlined
                :placeholder="translation_enter_nrc"
                :rules = "$store.state.locale == 'en' ? nrcRules : nrcRulesMM"
                background-color="#F4F6FA"
            >
            </v-text-field>

            <!-- DOB -->
            <div>
                <p class="heading" align="left">{{ $t("Customer.EditProfile.Date_Of_Birth") }}</p>

                <v-menu v-model="menu" ref="menu" offset-y transition="scale-transition" :close-on-content-click="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="editDOB" class="rounded-lg" style="border-color: #E0E0E0;" solo flat outlined
                            :placeholder="translation_dob"
                            :rules="$store.state.locale == 'en' ? dobRules : dobRulesMM"
                            v-bind="attrs"
                            v-on="on"
                            background-color="#F4F6FA"
                        >
                        </v-text-field>
                    </template>

                    <v-date-picker v-model="editDOB" full-width
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                        min="1900-01-01"
                        @change="getAge(editDOB), menu = false"
                    >
                    </v-date-picker>
                </v-menu>
            </div>

            <!-- Gender -->
            <div>
                <p class="heading" align="left">{{ $t("Customer.EditProfile.Gender") }}</p>
                <v-select v-if="$i18n.locale=='en'" v-model="editGender" class="rounded-lg" style="border-color: #E0E0E0;" solo flat outlined
                    :items="genderList" 
                    background-color="#F4F6FA"
                    placeholder="Gender"
                    item-text = "en_text"
                    return-object
                />

                <v-select v-if="$i18n.locale=='mm'" v-model="editGender" style="border-color: #E0E0E0;" solo flat outlined
                    :items="genderList"
                    background-color="#F4F6FA"
                    placeholder="ကျား/မ"
                    item-text = "mm_text"
                    return-object
                />
            </div>

            <!-- Email -->
            <div>
                <p class="heading" align="left">{{ $t("Customer.EditProfile.Email") }}</p>
                <v-text-field v-model="editEmail" class="rounded-lg" style="border-color: #E0E0E0;" solo flat outlined
                    :placeholder="translation_enter_email"
                    background-color="#F4F6FA"
                >
                </v-text-field>
            </div>

            <!-- Height -->
            <div>
                <p class="heading" align="left">{{ $t("Customer.EditProfile.Height") }}</p>
                <div class="d-flex">
                    <v-text-field v-model="heightInFeet" class="pr-2 rounded-lg" style="border-color: #E0E0E0;" type="number" solo flat outlined
                        :placeholder="translation_feet"
                        :rules="$store.state.locale == 'en' ? heightRules : heightRulesMM"
                        background-color="#F4F6FA"
                        :suffix="translation_Ft"
                    />
                    <v-text-field v-model="heightInInch" class="pl-2 rounded-lg" style="border-color: #E0E0E0;" type="number" solo flat outlined
                        :placeholder="translation_inches"
                        :rules="$store.state.locale == 'en' ? heightRulesIn : heightRulesInMM"
                        background-color="#F4F6FA"
                        :suffix="translation_In"
                    />
                </div>
            </div>

            <!-- Weight -->
            <div>
                <p class="heading" align="left">{{ $t("Customer.EditProfile.Weight") }}</p>
                <v-text-field v-model="editWeight" class="rounded-lg flexbox" style="border-color: #E0E0E0;" type="number" solo flat outlined
                    :placeholder="translation_in_lbs"
                    background-color="#F4F6FA"
                    :suffix="translation_Lb"
                />
            </div>

            <!-- Smoker -->
            <div>
                <p class="heading" align="left">{{ $t("Customer.EditProfile.Smoker") }}</p>
                <v-select v-if="$i18n.locale=='en'" v-model="editSmoking" class="rounded-lg flex-item" style="border-color: #E0E0E0;" type="number" solo flat outlined
                    :items="smokerList"
                    background-color="#F4F6FA"
                    item-text = "en_text"
                    return-object
                />
                <v-select v-if="$i18n.locale=='mm'" v-model="editSmoking" class="rounded-lg flex-item" style="border-color: #E0E0E0;" type="number" solo flat outlined
                    :items="smokerList"
                    item-text="mm_text"
                    background-color="#F4F6FA"
                    return-object
                />
            </div>

            <!-- Alcoholic -->
            <div>
                <p class="heading" align="left">{{ $t("Customer.EditProfile.Alcoholic") }}</p>
                <v-select v-if="$i18n.locale=='en'" v-model="editAlcoholic" class="rounded-lg flexbox" style="border-color: #E0E0E0;" type="number" solo flat outlined
                    item-text = "en_text"
                    :items="alcoholicList"
                    background-color="#F4F6FA"
                    return-object
                />

                <v-select v-if="$i18n.locale=='mm'" v-model="editAlcoholic" class="rounded-lg flexbox" style="border-color: #E0E0E0;" type="number" solo flat outlined
                    item-text = "mm_text"
                    :items="alcoholicList"
                    background-color="#F4F6FA"
                    return-object
                />
            </div>

            <!-- Save -->
            <div class="py-4">
                <v-btn class="py-6 rounded-lg" color="primary" style="width:100%; margin-top:-3vh;" @click="updateCustomerInfo" :disabled="isSaveButtonClicked || !(editName != '' && editGender != '' && editDOB != '' && editMobileNumber != '' && editNRC != '')">
                    {{ $t("Customer.EditProfile.Save") }}
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'editProfileBrowserUserComponent',
    props: {
        customer_data: {
            type: Object,
            requied: true
        }
    },
    data() {
        return {
            isSaveButtonClicked: false,
            editProfileSuccessModal: false,
            auth_error_enable: false,
            error_enable: false,
            ageLimitModal: false,
            translation_enter_name: '',
            translation_dob: '',
            translation_enter_email: '',
            translation_enter_number: '',
            translation_enter_nrc: '',
            translation_in_lbs: '',
            translation_feet: '',
            translation_inches: '',
            translation_Ft: '',
            translation_In: '',
            translation_Lb: '',
            editName: '',
            editMobileNumber: '',
            editGender: '',
            editDOB: '',
            editEmail: '',
            editNRC: '',
            heightInFeet: '',
            heightInInch: '',
            editWeight: '',
            editSmoking: '',
            editAlcoholic: '',
            genderList :[{ "key": "male", "mm_text": "ကျား", "en_text": "Male" },{ "key": "female", "mm_text": "မ", "en_text": "Female" }, { "key": "other", "mm_text": "အခြား", "en_text": "Other" }],
            alcoholicList : [{ "key": "non_drinker", "mm_text": "အရက်လုံးဝမသောက်ပါ", "en_text": "Non drinker" }, { "key": "social_drinker", "mm_text": "လူမှုရေးအရသောက်ပါသည်", "en_text": "Social drinker" }, { "key": "moderate_drinker", "mm_text": "အလွန်အကြူးမသောက်ပါ", "en_text": "Moderate drinker" }, { "key": "alcoholic", "mm_text": "အရက်စွဲနေပါသည်", "en_text": "Alcoholic" }],
			smokerList :  [{ "key": "yes", "mm_text": "သောက်သည်", "en_text": "Yes" }, { "key": "no", "mm_text": "မသောက်ပါ", "en_text": "No" }],
            nameRules : [v => !!v || 'Name is required'],
            nameRulesMM : [v => !!v || 'အမည်ထည့်သွင်းရန် လိုအပ်ပါသည်။'],
            nrcRules : [v => !!v || 'NRC is required'],
            nrcRulesMM : [v => !!v || 'မှတ်ပုံတင် ထည့်သွင်းရန် လိုအပ်ပါသည်'],
            heightRules: [
			    (v) => (v && v <= 10) || "Height cannot be more than 10feet",
			],
			heightRulesMM: [
			    (v) => (v && v <= 10) || "အရပ်သည် ၁၀ ပေ အောက်ဖြစ်သင့်ပါသည်။",
			],
            dobRules : [v => !!v || 'Date of Birth is required'],
            dobRulesMM : [v => !!v || 'မွေးနေ့ထည့်သွင်းရန် လိုအပ်ပါသည်။'],
            heightRulesIn : [v => v <= 12 || 'Inches can not be greater than 12'],
            heightRulesInMM : [v => v <= 12 || 'လက်မသည် ၁၂ လက်မအောက်ဖြစ်ရပါမည်။'],
            menu:false,
        }
    },
    mounted() {
        // this.editName = this.customer_data.customer_name;
        this.editName = this.$store.state.name;
        this.editMobileNumber = this.customer_data.mobile_number;
        this.editEmail = this.customer_data.email;
        this.editGender = this.customer_data.gender;
        this.editDOB = this.customer_data.dob;
        this.editNRC = this.customer_data.NRC;
        this.heightInFeet = this.customer_data.heightInFeet;
        this.heightInInch = this.customer_data.heightInInch;
        this.editWeight = this.customer_data.weight;
        this.editSmoking = this.customer_data.smoker;
        this.editAlcoholic = this.customer_data.alcoholic;

        if (this.$i18n.locale == "en") {
            this.translation_enter_name = "Enter Name";
            this.translation_dob = "Enter Date Of Birth";
            this.translation_enter_email = "Enter Email";
            this.translation_enter_number = "Enter Phone Number";
            this.translation_enter_nrc = "Enter NRC";
            this.translation_in_lbs = "in lbs";
            this.translation_feet = "feet";
            this.translation_inches = "inches";
            this.translation_Ft = "ft";
            this.translation_In = "in";
            this.translation_Lb = "lb";
        } else {
            this.translation_enter_name = "အမည်ထည့်မည်";
            this.translation_dob = "မွေးနေ့";
            this.translation_enter_email = "အီးလ်မေး";
            this.translation_enter_number = "ဖုန်းနံပါတ် ဖြည့်ပါ";
            this.translation_enter_nrc = "မှတ်ပုံတင်နံပါတ် ဖြည့်ပါ";
            this.translation_in_lbs = "ပေါင်";
            this.translation_feet = "ပေ";
            this.translation_inches = "လက်မ";
            this.translation_Ft = "ပေ";
            this.translation_In = "လက်မ";
            this.translation_Lb = "ပေါင်";
        }
    },
    methods: {
        closeDialogUnauthorized() {
            this.auth_error_enable = false;
            this.$router.push({
                name: "Login",
            });
        },
        closeDialogError() {
            this.error_enable = false;
            this.$router.push({
				name: "UserProfile",
			});
        },
        closeAgeLimitModal() {
            this.ageLimitModal = false;
     	},
        closeEditProfileSuccessModal(){
			this.editProfileSuccessModal = false;
			this.$router.push({
				name: "UserProfile",
			});
		},
        getAge(dateString) {
            let today = new Date();
            let birthDate = new Date(dateString);
            let age = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            if(age < 18){
                this.ageLimitModal = true;
                this.editDob = "";
            }
        },
        updateCustomerInfo() {
            this.isSaveButtonClicked = true;
			this.editHeight = this.heightInFeet +"\'"+ this.heightInInch + "\"";

			let modifyCustomerData = {
				token : this.customer_data.token,
				typeOfUser : 'CUSTOMER',
				customerName : this.editName,
				height : this.editHeight ? this.editHeight : '0\'0"',
				weight : this.editWeight ? this.editWeight : 0,
				email : this.editEmail ? this.editEmail : '',
				dob : this.editDOB,
				gender : this.editGender.en_text,
				smoker : this.editSmoking ? this.editSmoking.en_text : '',
				alcoholic : this.editAlcoholic ? this.editAlcoholic.en_text : '',
				nrc: this.editNRC
			}
			axios.post(process.env.VUE_APP_BACKEND_URL+"/ModifyBrowserCustomer",modifyCustomerData)
			.then(() => {
				this.isSaveButtonClicked= false;
				this.editProfileSuccessModal = true;
			})
			.catch((modifyCustomerError) => {
                console.log('Error updating customer : ', modifyCustomerError);
                this.error_enable = true;
			});
        }
    }
}
</script>

<style>
.disabledClass .v-text-field__slot input {
	color: white !important;
}
.disabledClass .v-select__selection--disabled {
	color: white !important;
}
.v-select__selection {
  line-height: 2.2 !important;
}
.v-select__slot.v-select__selection.v-select__selection--comma{
  line-height: 2.2 !important;
}
.v-list-item .v-list-item__title{
	line-height: 2.2 !important;
}
</style>

<style scoped>
.heading {
    font-size: 16px;
    font-weight: bold;
}
</style>